/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery.easing@1.4.1/jquery.easing.min.js
 * - /npm/popper.js@1.15.0/dist/umd/popper.min.js
 * - /npm/bootstrap@4.3.1/dist/js/bootstrap.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/skrollr@0.6.26/dist/skrollr.min.js
 * - /npm/lozad@1.9.0/dist/lozad.min.js
 * - /npm/lightgallery@1.6.12/dist/js/lightgallery-all.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
